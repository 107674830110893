import React from "react";
import Img from "gatsby-image";
import clsx from "clsx";
import { useInView } from "react-intersection-observer";

const FigureWithGradientPlaceholder = ({ input, alt, imgStyle = {} }) => {
  const [ref, inView, entry] = useInView({
    threshold: 0,
  });
  // console.log(input);
  const start = "#bcbebe";
  const end = "#ffffff";

  return (
    <div
      className='figure-with-gradient-placeholder'
      style={imgStyle}
      ref={ref}>
      <div
        className={clsx(
          "bg-placeholder absolute h-full w-full transition-opacity",
          inView ? "opacity-0" : "opacity-1"
        )}
        style={{
          backgroundImage: `linear-gradient(to bottom, ${start}, ${end})`,
        }}></div>
      <div
        className={clsx(
          "img-wrap transition-opacity",
          inView ? "opacity-100" : "opacity-0"
        )}>
        {/* {inView && (
          <img
            itemProp='contentUrl'
            alt={input.alt}
            src={input.url}
            srcSet={input.fluid.srcSet}
            sizes={input.fluid.sizes}
          />
        )} */}
        <Img
          fluid={input.fluid}
          alt={input.alt ? input.alt : alt}
          fadeIn={false}
          durationFadeIn={0}
          itemProp={`contentUrl`}
        />
      </div>
    </div>
  );
};

export default FigureWithGradientPlaceholder;
